<script lang="ts" setup>
const props = defineProps({ h1String: String });
const showDrawer = ref(false);
const onClickedNav = () => {
  showDrawer.value = !showDrawer.value;
  window.scrollTo(0, 0);
};
</script>

<template lang="pug">
div  
  v-app-bar.nav-bar-desktop(elevation="0" height="85")
    template(v-slot:prepend)
      v-btn(variant="text" stacked class="logo-btn")
        HeaderLogo      
        h1(v-if="h1String"): b {{ h1String }}
    .gnav
      v-btn(variant="text" stacked class="font-weight-bold")
        a(href="/dog-run/search") DOG RUN
          br
          span ドッグラン
      v-btn(variant="text" stacked class="font-weight-bold")
        a(href="/dog-cafe/search") DOG CAFE
          br
          span 愛犬と入れるカフェ
      v-btn(variant="text" stacked class="font-weight-bold")
        a(href="/dog-hotel/search") DOG HOTEL
          br
          span 愛犬と泊まれるホテル
      v-btn(variant="text" stacked class="font-weight-bold")
        a(href="/campsite/search") CAMPSITE
          br
          span 愛犬とキャンプ
      v-btn(variant="text" stacked class="font-weight-bold")
        a(href="/pet-salon/search") DOG SALON
          br
          span 愛犬のためのサロン
      v-btn(variant="text" stacked class="font-weight-bold")
        a(href="/articles") MAGAZINE
          br
          span マガジン
        
  div.nav-drawer-mobile
    v-container(fluid class="ma-0")
      v-row(ma-0)
        v-col(cols="10" class="pb-0")
          HeaderLogo
          h1(v-if="h1String"): b {{ h1String }}
        v-col(cols="2" class="pb-0")
          v-app-bar-nav-icon(xLarge @click.stop="onClickedNav")
  v-navigation-drawer(
    v-model="showDrawer"
    fixed
    temporary
    location="right"
  )
    v-container(fluid class="pt-10")
      v-list-item(class="close-btn" link)
        v-list-item-action
          v-icon(large @click.stop="showDrawer = false") mdi-close
      v-list-item(link href="/")
        template(v-slot:prepend)
          v-icon mdi-home
        v-list-item-title HOME
        v-list-item-subtitle ホーム
      v-list-item(link href="/dog-run/search")
        template(v-slot:prepend)
          v-icon mdi-paw
        v-list-item-title DOG RUN
        v-list-item-subtitle ドッグラン
      v-list-item(link href="/dog-cafe/search")
        template(v-slot:prepend)
          v-icon mdi-coffee
        v-list-item-title DOG CAFE
        v-list-item-subtitle 愛犬と入れるカフェ
      v-list-item(link href="/dog-hotel/search")
        template(v-slot:prepend)
          v-icon mdi-bed
        v-list-item-title DOG HOTEL
        v-list-item-subtitle 愛犬と泊まれるホテル
      v-list-item(link href="/campsite/search")
        template(v-slot:prepend)
          v-icon mdi-campfire
        v-list-item-title CAMPSITE
        v-list-item-subtitle 愛犬とキャンプ
      v-list-item(link href="/pet-salon/search")
        template(v-slot:prepend)
          v-icon mdi-shower
        v-list-item-title DOG SALON
        v-list-item-subtitle 愛犬のためのサロン
      v-list-item(link href="/articles")
        template(v-slot:prepend)
          v-icon mdi-newspaper-variant-multiple-outline
        v-list-item-title MAGAZINE
        v-list-item-subtitle マガジン
      v-list-item(link href="/#news")
        template(v-slot:prepend)
          v-icon mdi-bell-alert-outline
        v-list-item-title NEWS
        v-list-item-subtitle お知らせ
</template>

<style lang="scss">
.v-navigation-drawer__content {
  font-family: brandon-grotesque, sans-serif;
}

.nav-bar-desktop {
}
.nav-drawer-mobile {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
}
.nav-bar-desktop,
.nav-drawer-mobile {
  display: block;
  width: 100%;
  background-color: white;
  border-bottom: 1px solid #f0f0f0;

  /* v-btn style をカスタマイズ */
  .logo-btn:hover {
    .v-btn__overlay {
      opacity: 0;
    }
  }

  /* v-btn style をカスタマイズ */
  .logo-btn {
    h1 {
      font-size: 0.4rem;
      text-align: center;
      color: #707070;
      padding-left: 20px;
      letter-spacing: 0.01rem;
    }
    span.v-btn__content {
      align-items: baseline;
    }
  }
  .gnav {
    position: absolute;
    top: 0;
    right: 0;
    padding-right: 20px;
    margin: auto;
    font-family: brandon-grotesque, sans-serif;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 0.96px;

    .v-btn {
      line-height: 1.5;

      span {
        font-family: a-otf-gothic-bbb-pr6n, sans-serif;
        font-size: 0.4rem;
        padding: 0;
      }

      span.v-btn__content {
        letter-spacing: 0.09rem;
      }
    }

    a {
      display: inline-block;
      margin: 0 auto;
      font-size: 0.9rem;
      text-align: center;
      text-decoration: none;
      color: #707070;
    }

    a:hover {
      opacity: 0.4;
      -webkit-transition: all 0.5s linear;
      -moz-transition: all 0.5s linear;
      -o-transition: all 0.5s linear;
      -ms-transition: all 0.5s linear;
      transition: all 0.5s linear;
    }
  }
  .v-list-item-subtitle {
    font-size: 0.7rem;
  }
}

.nav-drawer-mobile {
  display: none;
}

@media (max-width: 768px) {
  .nav-bar-desktop {
    display: none !important;
  }

  .nav-drawer-mobile {
    display: block;
    height: 95px;

    h1 {
      padding-left: 0px;
    }
    h1 {
      font-size: 0.4rem;
      text-align: center;
      color: #707070;
      padding-left: unset;
    }
  }

  .close-btn {
    position: absolute;
    right: 3px;
    top: 0px;
    padding: 0px;
    margin: 0px;
  }
}
</style>
