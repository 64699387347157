<script lang="ts" setup>
import { Tag } from "@/models/tag";
const props = defineProps({
  tags: Array<Tag>,
  placeType: "dog-run" | "dog-cafe" | "dog-hotel" | "pet-salon" | "campsite",
});

const placeTypeLabel = computed(() => {
  switch (props.placeType) {
    case "dog-run":
      return "ドッグラン";
    case "dog-cafe":
      return "カフェ";
    case "dog-hotel":
      return "ホテル";
    case "pet-salon":
      return "サロン";
    case "campsite":
      return "キャンプ場";
    default:
      return;
  }
});
</script>

<template lang="pug">

v-row.tag-link-box(v-if="tags" no-gutters)
  v-col.text-left(
    v-for="(tag, index) in tags"
    cols="2"
    :offset="index === 0 ? 1 : 1"
    :key="tag.slug"
  )
    a(
      :href="`/${placeType}/search?tags[]=${tag.slug}`"
      class="link"
    ) {{tag.name}}
</template>

<style lang="scss" scoped>
.tag-link-box {
  .v-col-1 {
    flex: 1;
  }

  a.link {
    font-size: 0.8rem;
    color: black;
  }
}
</style>
