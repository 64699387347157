<script lang="ts" setup>
import prefectures from "~/assets/data/prefectures";
const props = defineProps({
  area: Object,
  placeType: "dog-run" | "dog-cafe" | "dog-hotel" | "pet-salon" | "campsite",
});

const getPrefectures = (area_id) => {
  return prefectures.filter((p) => p["area_id"] == area_id);
};

const placeTypeLabel = computed(() => {
  switch (props.placeType) {
    case "dog-run":
      return "ドッグラン";
    case "dog-cafe":
      return "カフェ";
    case "dog-hotel":
      return "ホテル";
    case "pet-salon":
      return "サロン";
    case "campsite":
      return "キャンプ場";
    default:
      return;
  }
});
</script>

<template lang="pug">

v-row.pref-link-box(v-if="area" no-gutters)
  v-col.text-left(cols="12" offset="1" md="1")
    b {{area.name}}
  v-col.text-left(
    cols="4"
    offset="1"
    offset-md="0"
    md="1"
    v-for="pref in getPrefectures(area.id)"
    :key="pref.id"
    class="mr-1"
  )
    a.link(:href="`/${placeType}/search?prefs[]=${pref.sym}`") {{`${pref.name}`}}の{{placeTypeLabel}}
</template>

<style lang="scss" scoped>
.pref-link-box {
  .v-col-1 {
    flex: 1;
  }

  a.link {
    font-size: 0.8rem;
    color: black;
  }
}
</style>
